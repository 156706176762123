/* eslint-disable */
import {HttpResponse} from '../interfaces/http-response';
import {AuthUser} from "../interfaces/auth-user";
import {CONTENTFLY_STORAGE_AUTH} from "../constants";
import { Storage } from '@capacitor/storage';

class Auth{
    private _token: string = null;
    private _user: AuthUser = null;

    constructor() {
    }

    clear(){
        this._token = null;
        this._user  = null;
        return Storage.remove({key: CONTENTFLY_STORAGE_AUTH});
    }

    getToken() : string{
        return this._token;
    }

    getUser() : AuthUser{
        return this._user;
    }

    migrateFromLocalStorage(){
        const dataStr = window.localStorage.getItem(CONTENTFLY_STORAGE_AUTH);
        if(dataStr == null) return Promise.resolve();

        return Storage.set({
            key: CONTENTFLY_STORAGE_AUTH,
            value: dataStr,
        }).then(() => {
            window.localStorage.removeItem(CONTENTFLY_STORAGE_AUTH);
            return Promise.resolve();
        })
    }

    init(){
        console.log("initt");
        return this.migrateFromLocalStorage().then(() => {
            console.log("after migration")
            return Storage.get({key: CONTENTFLY_STORAGE_AUTH});
        }).then((dataStr) => {
            console.log("load auth", dataStr)
            if(dataStr == null || dataStr.value == null) return Promise.resolve();
            console.log("INIT", dataStr.value);
            const data = JSON.parse(dataStr.value);
            this._token = data.token;
            this._user  = data.user;

            return Promise.resolve()
        })

    }

    save(httpResponse: HttpResponse){
        this._token = httpResponse.token;
        this._user  = httpResponse.user;

        const data = {token: this._token, user: this._user};
        Storage.set({key: CONTENTFLY_STORAGE_AUTH, value: JSON.stringify(data)})
    }
}

export const auth = new Auth();
