/* eslint-disable */
import {Http} from "@capacitor-community/http";
import {auth} from "./auth";

export class Api{
    private _url: string;

    constructor(url: string) {
        this._url = url.slice(-1) === '/' ? url : url + '/';
    }

    get(endpoint: string, params: any = {}){


        const options = {
            url: this._url + endpoint,
            params,
        };

        if(auth.getToken()){
            options['headers'] = { 'APPCMS-TOKEN': auth.getToken() };
        }

        return Http.get(options);
    }

    post(endpoint: string, params: any = {}){

        const headers = {
            'Content-Type' : 'application/json',
            'contentfly-ionic': 'sdk'
        };

        if(auth.getToken()){
            headers['APPCMS-TOKEN'] = auth.getToken()
        }

        const options = {
            url: this._url + endpoint,
            headers,
            data: params,
        };

        return Http.post(options);
    }

}
