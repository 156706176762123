import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NavController} from '@ionic/angular';
import {contentflySdk} from 'contentfly-capacitor/src/contentfly-sdk';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private navCtrl: NavController) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(contentflySdk.isAuthorized()){
            return true;
        }
        this.navCtrl.navigateRoot('/login');
        return false;
    }
}