import {Component, NgZone, OnInit} from '@angular/core';
import {contentflySdk} from './vendor/contentfly-capacitor/src/contentfly-sdk';
import {MenuController, NavController, Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import {LanguageService} from './services/language.service';
import {ContentLiveService} from './services/content-live.service';
import {Purchases} from '@awesome-cordova-plugins/purchases/ngx';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  flagClass: string;

  constructor(
      private contentLiveSrv: ContentLiveService,
      private languageSrv: LanguageService,
      private menuCtrl: MenuController,
      private navCtrl: NavController,
      public platform: Platform,
      private purchases: Purchases,
      private zone: NgZone
  ) {

    this.platform.ready().then(() => {

      this.purchases.setDebugLogsEnabled(true);

      this.languageSrv.init();
      this.flagClass = 'fcapp-flag fcapp-flag-' + this.languageSrv.current;

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

      this.toggleDarkTheme(prefersDark.matches);
      prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

      this.checkSubscription();
      return Device.getInfo();
    }).then((info) => {
      if(info.platform === 'android'){
        this.purchases.setup('goog_MfFDhplvYwonFzMETlanYYcGvrl');
      }else{
        this.purchases.setup('appl_DJQoGrtlbtgizyDvFUuSRonhmps');
      }
    });
  }

  checkSubscription(){

    App.addListener('appStateChange', ({ isActive }) => {
      if(isActive && contentflySdk.isAuthorized()){
        const user = contentflySdk.getAuth().getUser();
        if(user.loginManager === 'Custom\\Classes\\revenuecat'){
          this.purchases.getPurchaserInfo().then((purchaserInfo) => {
            if (typeof purchaserInfo.entitlements.active.thechicodesubscription === 'undefined') {
              contentflySdk.logout();
            }
          });
        }

      }
    });
  }

  goto(route: string){
    this.menuCtrl.close();
    this.navCtrl.navigateRoot(route);
  }

  logout(){
    contentflySdk.logout().then(() => {
      this.menuCtrl.close();
      this.navCtrl.navigateRoot('login');
    });
  }


  ngOnInit() {

  }

  toggleDarkTheme(shouldAdd) {
    const today = new Date();
    if(today.getHours() >= 19 || today.getHours() <= 9){
      shouldAdd = true;
    }

    document.body.classList.toggle('dark', shouldAdd);
  }

  toggleLanguage(lang){
    this.languageSrv.toggle(lang);
    this.flagClass = 'fcapp-flag fcapp-flag-' + this.languageSrv.current;
    this.contentLiveSrv.forceReload();
  }
}
