import { Injectable } from '@angular/core';
import {contentflySdk} from 'contentfly-capacitor/src/contentfly-sdk';
import {ContentService} from '../interfaces/content-service';
import {LanguageService} from './language.service';
import {Content} from '../interfaces/content';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {NavController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ContentLiveService implements ContentService{

  public breadcrumbs: Content[] = [];
  public reload$: Observable<any>;
  private reload: Subject<void> = new Subject();

  constructor(
      private languageSrv: LanguageService,
      private navCtrl: NavController
  ) {
    this.reload$ = this.reload.asObservable();
  }

  clearBreadcrumbs(){
    this.breadcrumbs = [];
  }

  fetch(parentId?: string): Promise<Content[]>{

    const params: any = {
      entity: 'Content',
      order: {sorting: 'ASC'},
      flatten: true
    };

    if(parentId){
      params.where = {treeParent: parentId};
    }else{
      params.where = {treeParent: -1};
    }

    return contentflySdk.getApi().post('api/list', params).then((response) => {
      console.log(response);
      if(response.status === 401){
        this.navCtrl.navigateRoot('login');
        return [];
      }
      const contents = response.data.data.map((item) => ({
        id: item.id,
        parentId: item.treeParent ? item.treeParent.id : null,
        image: item.image ? environment.serverUrl + 'file/get/' + item.image.id + '/s-' + environment.imageSizeAlias : null,
        title: this.languageSrv.current === 'en' ? item.title_en : (this.languageSrv.current === 'es' ? item.title_es : item.title),
        text: this.languageSrv.current === 'en' ? item.text_en : (this.languageSrv.current === 'es' ? item.text_es : item.text)
      }));
      return contents;
    }).catch((error) => []);
  }

  fetchBreadcrumbs(id: string){
    const params: any = {
      entity: 'Content',
      id
    };

    return contentflySdk.getApi().post('api/parents', params).then((response) => {
      const contents = response.data.data.map((item) => ({
        id: item.id,
        parentId: item.treeParent ? item.treeParent.id : null,
        image: item.image ? environment.serverUrl + 'file/get/' + item.image.id + '/s-' + environment.imageSizeAlias : null,
        title: this.languageSrv.current === 'en' ? item.title_en : (this.languageSrv.current === 'es' ? item.title_es : item.title),
        text: this.languageSrv.current === 'en' ? item.text_en : (this.languageSrv.current === 'es' ? item.text_es : item.text)
      }));
      this.breadcrumbs = contents;
      return contents;
    }).catch((error) => null);
  }

  forceReload(){
    this.reload.next();
  }

  single(id: string): Promise<Content|null>{
    if(!id){
      return Promise.resolve(null);
    }

    const params: any = {
      entity: 'Content',
      id
    };
    return this.fetchBreadcrumbs(id).then(() => contentflySdk.getApi().post('api/single', params).then((response) => {
        const item = response.data.data;
        const content = {
          id: item.id,
          parentId: item.treeParent ? item.treeParent.id : null,
          image: item.image ? environment.serverUrl + 'file/get/' + item.image.id + '/s-' + environment.imageSizeAlias : null,
          title: this.languageSrv.current === 'en' ? item.title_en : (this.languageSrv.current === 'es' ? item.title_es : item.title),
          text: this.languageSrv.current === 'en' ? item.text_en : (this.languageSrv.current === 'es' ? item.text_es : item.text)
        };
        if(!this.breadcrumbs.length || this.breadcrumbs[this.breadcrumbs.length - 1].id !== content.id){
          this.breadcrumbs.push(content);
        }
        return content;
      }));
  }

}
