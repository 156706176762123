/* eslint-disable */
import {ContentflyOptions} from "./interfaces/contentfly-options";
import {Api} from "./services/api";
import {auth} from "./services/auth";

class ContentflySdk{
    public _api: Api;

    constructor() {
    }

    getApi(){
        return this._api;
    }

    getAuth(){
        return auth;
    }

    init(options: ContentflyOptions){
        this._api = new Api(options.url);
        return auth.init();
    }

    isAuthorized(){
      return auth.getToken() != null;
    }

    login(alias: string, pass: string, loginManager?: string){

        let params : any = {
            alias,
            pass,
            withSchema: true
        };

        if(loginManager){
            params.loginManager = loginManager;
        }

        return this._api.post('auth/login', params).then((response) => {
            console.log(response);
            if(response.status !== 200){

                return Promise.reject(response.data.message);
            }
            console.log("ok");
            auth.save(response.data);

            return true;
        });
    }

    logout(){
        return this._api.get('auth/logout').then((response) => {
            auth.clear();
            return true;
        })
    }
}

export const contentflySdk = new ContentflySdk();
