import { Injectable } from '@angular/core';
import {FCAPP_STORAGE_LANGUAGE} from '../constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  current: string;

  constructor(private translate: TranslateService) {

  }

  init(){
    this.current = window.localStorage.getItem(FCAPP_STORAGE_LANGUAGE);
    this.current = this.current ? this.current : 'de';
    this.translate.setDefaultLang('de');
    this.translate.use(this.current);
    console.log(this.translate.currentLang);
  }

  toggle(lang){
    this.current = lang;
    window.localStorage.setItem(FCAPP_STORAGE_LANGUAGE, this.current);
    this.translate.use(this.current);
  }
}
