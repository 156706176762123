import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {AuthGuard} from './guards/auth';
import {NotAuthGuard} from './guards/notauth';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {contentflySdk} from 'contentfly-capacitor/src/contentfly-sdk';
import {environment} from '../environments/environment';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

export function appInitializerFactory() {
  return (): Promise<any> => {
    return contentflySdk.init({url: environment.serverUrl});
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true
    },
    AuthGuard,
    NotAuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
